import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'muse-ui/dist/muse-ui.css';
import "./assets/js/rem.js";
import router from './router'
import axios from './http/index'
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$url = 'http://static.comegogogo.com'
Vue.prototype.QINIU_CDN_HOST = 'http://static.comegogogo.com/'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@vant/touch-emulator'
Vue.use(Vant)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression]
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
