import axios from 'axios'
import 'muse-ui-loading/dist/muse-ui-loading.css'; // load css
// import Vue from 'vue';
// import Loading from 'muse-ui-loading';

// Vue.use(Loading);

let apiUrl = ''
if (window.location.host == "panorama.comegogogo.com" || window.location.host == "panorama.mobile.comegogogo.com") {
    apiUrl = "https://www.comegogogo.com/";
}else {
    apiUrl = 'https://test.comegogogo.com/';
}
axios.defaults.timeout = 60000;
// axios.defaults.baseURL = 'http://store.comegogogo.com/';
axios.defaults.baseURL = apiUrl


// Content-Type: application/json => Content-Type: application/x-www-form-urlencoded
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.transformRequest = function (data) {
    let ret = ''
    for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret
};

var loadingNum = 0; // 防止异步接口清除其它的loading

axios.interceptors.request.use(function (config) {
    if (config.data && JSON.stringify(config.data).indexOf('none_loading') == -1) {
        loadingNum++;
        // Loading.open('');
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if (loadingNum > 0) {
        loadingNum--;
    }
    // if (loadingNum == 0) { Loading.close(''); }
    return response;
}, function (error) {
    loadingNum--;
    // if (loadingNum == 0) { Loading.close(''); }
    return Promise.reject(error);
});

export default axios;