<template>
  <!-- <div id="app"> -->
  <!-- <div id="app"> -->
  <div id="app" :style='[bgVr?"":{"background-image":"url("+bgImg+")"}]'>
    <!-- <iframe v-if="bgType == 0" :src="bgImg" frameborder="0" width="100%" height='100%' class="iframe"></iframe> -->
    <!-- <keep-alive>
      <router-view v-if='$route.meta.keepAlive'></router-view>
    </keep-alive>
    <router-view v-if='!$route.meta.keepAlive'></router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        bgImg: '',
        bgVr: '',
      }
    },
    mounted() {
      this.bgImg = localStorage.getItem("bgImg");
      this.bgVr = localStorage.getItem("bgVr");
      document.title = sessionStorage.getItem('title');
    },
    created() {
      
      // let meta = document.createElement('meta');
      // document.querySelector('meta[name="description"]').setAttribute('content', sessionStorage.getItem('description'));
      var sUserAgent = navigator.userAgent.toLowerCase();
      sessionStorage.setItem("isPad", (sUserAgent.match(/ipad/i) == "ipad" || sUserAgent.match(/macintosh/i) == "macintosh"));
      
      
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background: url('./assets/image/bg.jpg') no-repeat;
        background-size: 100% 100%; */
    -moz-user-select: none;
    /* Firefox */
    -webkit-user-select: none;
    /* WebKit */
    -ms-user-select: none;
    /* IE */
    -khtml-user-select: none;
    /* KHTML */
    -o-user-select: none;
    /* Opera */
    user-select: none;

  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  /* 这是针对缺省样式 (必须的) */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条的滑轨背景颜色 */

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* 滑块颜色 */

  ::-webkit-scrollbar-button {
    background-color: transparent;
  }

  /* 滑轨两头的监听按钮颜色 */

  ::-webkit-scrollbar-corner {
    background-color: black;
  }

  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
  /* @font-face {
      font-family: 'Roboto';
      src: url('Roboto-Regular-webfont.eot');
      src: url('Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('Roboto-Regular-webfont.woff') format('woff'),
      url('Roboto-Regular-webfont.ttf') format('truetype'),
      url('Roboto-Regular-webfont.svg#RobotoRegular') format('svg');
      font-weight: normal;
      font-style: normal;
 } */
</style>