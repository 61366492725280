import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
    {
        path: '/', // 首页
        name: 'index',
       
        component: resolve => require(['../views/index.vue'], resolve),
        children: [
        ]
    },
    {
        path: '/video', //
        name: 'video',
        component: resolve => require(['../views/video.vue'], resolve)
    },
    {
        path: '/vr', // 首页
        name: 'vr',
        component: resolve => require(['../views/vr.vue'], resolve)
    },
    {
        path: '/space', // 首页
        name: 'space',
        component: resolve => require(['../views/space.vue'], resolve),
        meta: {
            keepAlive: true
        },
        beforeEnter(to, from, next) {
            if (from.name == 'index') {
                sessionStorage.setItem('from', 'index')
            }else{
                sessionStorage.setItem('from', '')
            }
            next(vm => {
              // 通过 `vm` 访问组件实例
            //   console.log(vm)
            })
          }
    },
    {
        path: '/information', // 首页
        name: 'information',
        component: resolve => require(['../views/information.vue'], resolve)
    },
    {
        path: '/appointment', // 首页
        name: 'appointment',
        component: resolve => require(['../views/appointment.vue'], resolve)
    },
    {
        path: '/login', //登录
        name: 'login',
        component: resolve => require(['../views/login.vue'], resolve)
    },
    {
        path: '/code/:rent/:panorama', //登录
        name: 'code',
        component: resolve => require(['../views/code.vue'], resolve)
    },
    {
        path: '/outVr', //外部全景
        name: 'outVr',
        component: resolve => require(['../views/outVr.vue'], resolve)
    },
    //后台管理
    {
        path:'/manage-index',
        name:'manage-index',
        component: resolve => require(['../views/manage/index.vue'], resolve),
        redirect:{
            path:'/manage-stage',
        },
        
        children: [{
            path:'/manage-stage',
            name:'manage-stage',
            component: resolve => require(['../views/manage/stage.vue'], resolve)
        },{
            path:'/manage-space',
            name:'manage-space',
            component: resolve => require(['../views/manage/space.vue'], resolve)
        },{
            path:'/manage-building',
            name:'manage-building',
            component: resolve => require(['../views/manage/building.vue'], resolve)
        },{
            path:'/manage-team',
            name:'manage-team',
            component: resolve => require(['../views/manage/team.vue'], resolve)
        }]
    }
    ]
})